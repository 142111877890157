<template>
  <div>
    <b-form-group
      label="Proje"
      label-for="id_com_realty_project"
    >
      <validation-provider
        #default="{ errors }"
        name="Proje"
        rules="required"
      >
        <v-select
          id="id_com_realty_project"
          v-model="realtyNumber.id_com_realty_project"
          :options="projects"
          label="title"
          :reduce="project => project.id"
          placeholder="Proje"
          @input="getRealtyTypes"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BFormGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line
import vSelect from 'vue-select'

export default {
  name: 'Projects',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    projects() {
      return this.$store.getters['realtyProjects/getRealty_sale_projects']
    },
    realtyNumber() {
      return this.$store.getters['realtyNumbers/getRealty_sale_number']
    },
  },
  created() {
    this.getProjects()
    this.getRealtyTypes()
    localize('tr')
  },
  methods: {
    getProjects() {
      this.$store.dispatch('realtyProjects/realty_sale_projectsList', {
        select: [
          'com_realty_project.id AS id',
          'com_realty_project.title AS title',
        ],
      })
    },
    getRealtyTypes() {
      if (this.realtyNumber.id_com_realty_project) {
        this.getRealtyBlocks()
        this.$store.dispatch('realtyTypes/realty_sale_typesList', {
          select: [
            'com_realty_type.id AS id',
            'com_realty_type.title AS title',
          ],
          where: {
            'com_realty_type.id_com_realty_project': this.realtyNumber.id_com_realty_project,
          },
        })
      }
    },
    getRealtyBlocks() {
      if (this.realtyNumber.id_com_realty_project) {
        this.$store.dispatch('realtyBlocks/realty_sale_blocksList', {
          select: [
            'com_realty_block.id AS id',
            'com_realty_block.title AS title',
          ],
          where: {
            'com_realty_block.id_com_realty_project': this.realtyNumber.id_com_realty_project,
          },
        })
      }
    },
  },
}
</script>
