<template>
  <b-form-group
    label="Kapı No"
    label-for="number"
  >
    <validation-provider
      #default="{ errors }"
      name="Kapı No"
      rules="required"
    >
      <b-form-input
        id="number"
        v-model="realtyNumber.number"
        placeholder="Kapı No"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Number',
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  computed: {
    realtyNumber() {
      return this.$store.getters['realtyNumbers/getRealty_sale_number']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
