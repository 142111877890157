<template>
  <div>
    <b-form-group
      label="Gayrimenkul Tipi"
      label-for="id_com_realty_type"
    >
      <validation-provider
        #default="{ errors }"
        name="Gayrimenkul Tipi"
        rules="required"
      >
        <v-select
          id="id_com_realty_project"
          v-model="realtyNumber.id_com_realty_type"
          :options="types"
          label="title"
          :reduce="type => type.id"
          placeholder="Gayrimenkul Tipi"
          :disabled="!types.length"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BFormGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line
import vSelect from 'vue-select'

export default {
  name: 'Types',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  computed: {
    types() {
      return this.$store.getters['realtyTypes/getRealty_sale_types']
    },
    realtyNumber() {
      return this.$store.getters['realtyNumbers/getRealty_sale_number']
    },
  },
  mounted() {
    localize('tr')
    this.getRealtyTypes()
  },
  methods: {
    getRealtyTypes() {
      if (this.realtyNumber.id_com_realty_project) {
        this.$store.dispatch('realtyTypes/realty_sale_typesList', {
          select: [
            'com_realty_type.id AS id',
            'com_realty_type.title AS title',
          ],
          where: {
            'com_realty_type.id_com_realty_project': this.realtyNumber.id_com_realty_project,
          },
        })
      }
    },
  },
}
</script>
