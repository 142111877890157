<template>
  <div>
    <b-form-group
      label="Blok"
      label-for="id_com_realty_block"
    >
      <validation-provider
        #default="{ errors }"
        name="Blok"
        rules="required"
      >
        <v-select
          id="id_com_realty_block"
          v-model="realtyNumber.id_com_realty_block"
          :options="blocks"
          label="title"
          :reduce="block => block.id"
          placeholder="Blok"
          :disabled="!blocks.length"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BFormGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line
import vSelect from 'vue-select'

export default {
  name: 'Blocks',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  computed: {
    blocks() {
      return this.$store.getters['realtyBlocks/getRealty_sale_blocks']
    },
    realtyNumber() {
      return this.$store.getters['realtyNumbers/getRealty_sale_number']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
